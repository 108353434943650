<script setup lang="ts">
import { type ICredit } from '@/utils/jDroid'
import { PLAN_ENUM } from '@/components/organisation/interface/IInstituteSubscriptionInfo'
import Button from '@/components/atoms/button/index.vue'

import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue'
import battery0 from '@/assets/images/ide/battery.svg'
import battery1 from '@/assets/images/ide/battery-1.svg'
import battery2 from '@/assets/images/ide/battery-2.svg'
import battery3 from '@/assets/images/ide/battery-3.svg'
import battery4 from '@/assets/images/ide/battery-4.svg'

import jdroidService from '@/services/ide/jdroid.service'
import { getSubscriptionInfo } from '@/services/teach.service'
import { useAuthStore } from '@/stores/auth.store'
import { useSubscribeStore } from '@/stores/subscribe.store'
import { useOrganisationStore } from '@/stores/organisation.store'
import { useAssessmentStore } from '@/stores/assessment.store'
import { useJdroidStore } from '@/stores/jdroid.store'
import { IDEVIEWMODELS, AUTHMODELHS } from '@/utils/models'
import { UNLIMITED_LIMIT } from '@/utils/sharedData/plans'
import { TAB_BUTTON_POSITION } from '@/utils/tabs'

const props = defineProps({
  isChatJdroid: {
    type: Boolean,
    required: false,
    default: false
  },
  tabPosition: {
    type: String,
    required: false
  }
})

const authStore = useAuthStore()
const subscribeStore = useSubscribeStore()
const jdroidStore = useJdroidStore()
const organisationStore = useOrganisationStore()
const assessmentStore = useAssessmentStore()

const quota = computed(() => jdroidStore.quota)
const dispalyQuota = computed(() => (quota.value === UNLIMITED_LIMIT ? 'Unlimited' : quota.value))
const used = computed(() => jdroidStore.used)

const isHttpLoading = ref<boolean>(false)
const httpError = ref<string | null>(null)

const isInitiated = computed(() => {
  return authStore.isInitiated
})
const isLoggedIn = computed(() => {
  return authStore.isInitiated && authStore.isUserloggedIn
})
const isFetchingSubscriptionInfo = computed(() => {
  return subscribeStore.isFetchingSubscriptionInfo
})
const accountType = computed(() => {
  return (organisationStore.instituteSubscriptionInfo?.plan as string) || null
})
const hsModel = computed(() => {
  if (!isLoggedIn.value) {
    // Redirect to chatjdroid after login
    return AUTHMODELHS.LOGIN
  } else return IDEVIEWMODELS.JDROIDGETMORECREDITS
})

const chatList = computed(() => jdroidStore.chatList.length)

const planName = computed(() => {
  if (accountType.value === PLAN_ENUM.FREE) {
    return 'Free'
  } else if (accountType.value === PLAN_ENUM.PRO) {
    return 'Pro'
  } else if (accountType.value === PLAN_ENUM.TEAM) {
    return 'Team'
  } else if (accountType.value === PLAN_ENUM.CUSTOM) {
    return 'Custom'
  } else {
    return null
  }
})

const isFreePlan = computed(() => {
  return accountType.value === PLAN_ENUM.FREE
})

const promoteGetCredit = computed(() => {
  return quota.value !== UNLIMITED_LIMIT
})

const useagePrecentage = computed(() => {
  if (quota.value === UNLIMITED_LIMIT) {
    return 100
  }
  return Math.floor(((quota.value - used.value) / quota.value) * 100)
})

const remainingCredits = computed(() => {
  if (quota.value === UNLIMITED_LIMIT) {
    return 'Unlimited'
  }
  return quota.value - used.value
})

const isOwner = computed(() => {
  return useOrganisationStore().isOwner
})
/**
 * post init details
 * @param count - number of times to retry
 */
const postInitDetails = async (count: number = 0) => {
  if (!isInitiated.value) {
    if (count > 40) {
      return
    } else {
      await new Promise((resolve) => setTimeout(resolve, 100))
      postInitDetails(count + 1)
    }
  } else {
    httpError.value = null
    isHttpLoading.value = true
    if (isInitiated.value)
      await jdroidService
        .getCredits()
        .then((res: ICredit) => {
          jdroidStore.setCredits(res)
        })
        .catch((err: string) => {
          httpError.value = err
        })
        .finally(() => {
          isHttpLoading.value = false
        })
  }
}
/**
 * toggle details
 */
const toggleDetails = async () => {
  await postInitDetails()
}

/**
 * get subscription info
 */
const fetchSubscriptionInfo = async () => {
  subscribeStore.setIsFetchingSubscriptionInfo(true)
  if (organisationStore.instituteSubscriptionInfo && isOwner.value) {
    try {
      const res = await getSubscriptionInfo()
      organisationStore.setSubscriptionInfo(res)
      assessmentStore.setSubscriptionInfo(res)
    } catch (error) {
      subscribeStore.setIsFetchingSubscriptionInfo(false)
    }
  }
  subscribeStore.setIsFetchingSubscriptionInfo(false)
}

/**
 * @description - show the icon according to the percentage
 * @returns icon and color
 */
const calculateQuota = () => {
  switch (true) {
    case useagePrecentage.value >= 70:
      return { icon: battery4, color: 'bg-green-400' }

    case useagePrecentage.value > 50 && useagePrecentage.value <= 70:
      return { icon: battery3, color: 'bg-green-300' }

    case useagePrecentage.value > 20 && useagePrecentage.value <= 50:
      return { icon: battery2, color: 'bg-red-200' }

    case useagePrecentage.value > 0 && useagePrecentage.value <= 20:
      return { icon: battery1, color: 'bg-red-400' }

    case useagePrecentage.value === 0:
      return { icon: battery0, color: 'bg-red-400' }
  }
}

onMounted(async () => {
  toggleDetails()
  isHttpLoading.value = false
  httpError.value = null

  if (isLoggedIn.value) await fetchSubscriptionInfo()
  watch(isLoggedIn, async (value) => {
    if (value) {
      await fetchSubscriptionInfo()
      toggleDetails()
    }
  })
  watch(chatList, () => {
    isHttpLoading.value = false
    httpError.value = null
  })
})
onBeforeUnmount(() => {
  isHttpLoading.value = false
  httpError.value = null
})

watch(
  () => jdroidStore.getMoreCreditsUpdated,
  async () => {
    if (jdroidStore.getMoreCreditsUpdated && isOwner.value) {
      toggleDetails()
    }
  }
)
</script>

<template>
  <div v-show="promoteGetCredit">
    <div v-show="isInitiated && !isFetchingSubscriptionInfo" class="flex min-w-fit grow">
      <div v-if="!isHttpLoading && quota">
        <!-- IDE chat -->
        <div
          v-if="!isChatJdroid"
          class="hs-dropdown relative"
          :class="props.tabPosition === TAB_BUTTON_POSITION.MAXIMIZE && !chatList && 'pr-8'"
        >
          <img
            :src="calculateQuota()?.icon"
            class="hs-dropdown-toggle h-5 w-5 cursor-pointer"
            id="hs-dropdown-default"
          />
          <div
            v-if="!isChatJdroid"
            class="hs-dropdown-menu surface-secondary-base absolute top-8 z-10 w-56 flex-col rounded-lg opacity-0 shadow-xl transition-[opacity,margin] duration-[0.1ms] hs-dropdown-open:opacity-100"
            :class="props.tabPosition === TAB_BUTTON_POSITION.MAXIMIZE ? 'right-8' : 'right-0'"
            aria-labelledby="hs-dropdown-default"
          >
            <div class="flex items-center gap-2 p-2">
              <div
                v-if="planName != null && isOwner"
                class="text-center text-xs font-semibold capitalize"
              >
                {{ planName }} Plan
              </div>
              <div class="flex w-fit items-center gap-2">
                <div class="h-2 w-[50px] rounded-2xl bg-slate-500 p-0.5 lg:w-[100px]">
                  <div
                    class="left-0 top-0 z-10 h-1 rounded-2xl"
                    :class="calculateQuota()?.color"
                    :style="{ width: useagePrecentage + '%' }"
                  ></div>
                </div>
                <span class="text-center text-xs font-semibold capitalize"
                  >{{ remainingCredits }}/{{ dispalyQuota }}
                </span>
              </div>
            </div>

            <div class="text-mid p-2 text-xs font-light">
              {{ `Up to ${isLoggedIn ? '15' : '5'} per day credits. Ideal for basic usage.` }}
            </div>
            <div
              class="surface-secondary-header flex flex-col gap-2 p-2"
              v-if="!(isOwner && !isFreePlan)"
            >
              <span class="text-high text-xs font-medium"> Pro Plan </span>
              <span class="text-mid text-xs font-light"
                >Unlimited. Ideal for complex code generation & assistance.</span
              >
              <Button
                variant="filled"
                size="small"
                color="secondary"
                class="px-2 py-1 text-xs"
                :data-hs-overlay="`#${hsModel}`"
                >Upgrade to Pro plan</Button
              >
            </div>
          </div>
        </div>
        <!-- Chat jdroid credits -->
        <div v-else class="h-9 text-xs text-neutral-500 sm:h-6">
          Remaining credits
          <span class="font-semibold text-black">{{ remainingCredits }}/{{ dispalyQuota }}</span
          >, to get unlimited credits
          <button
            id="get-more-credits-chatjdroid"
            class="font-semibold text-black underline"
            :data-hs-overlay="`#${hsModel}`"
          >
            subscribe
          </button>
        </div>
      </div>
      <div v-else class="flex h-full w-full items-center justify-end p-2 sm:justify-between">
        <span class="text-center text-xs font-semibold tracking-[0.25em]">Loading...</span>
      </div>
    </div>
  </div>
</template>
