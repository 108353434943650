import editorService from '@/services/ide/editor.service'
import ideService from '@/services/ide/ide.service'
import jdroidService from '@/services/ide/jdroid.service'
import blocklyService from '@/services/ide/languages/blockly/blockly.service'
import liveCodingService from '@/services/ide/liveCoding.service'
import projectTreeService from '@/services/ide/projectTree.service'
import settingService from '@/services/ide/settings/setting.service'
import utilModelsService from '@/services/util.models.service'
import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore } from '@/stores/ide.store'
import { useJdroidStore } from '@/stores/jdroid.store'
import { useLiveCodeStore } from '@/stores/liveCode.store'
import { useProjectManager } from '@/stores/projectManager.store'
import { ADVANCEDIDETYPE, IDECONSTANT, SYNC_ERROR } from '@/utils/ide'
import { IDEVIEWMODELS } from '@/utils/models'
import { LANGUAGE_TYPE, languagesItems, type ILanguage } from '@/utils/sharedData/languages'
import axios from 'axios'
import { cloneDeep, compact, merge, remove } from 'lodash-es'
import {
  adjectives,
  colors,
  names,
  uniqueNamesGenerator,
  type Config
} from 'unique-names-generator'

export interface IDubplicateProjectNameRequest {
  filename: string
  isMultiFile?: boolean
  lang: string
}

export interface IRenameProjectNameRequest {
  id: number
  filename: string
}

export interface ICollaborator {
  email: string
  name: string
  sharedInGeneralAccess?: boolean
}
export interface IModelProject {
  id: string
  name: string
  language: string
  readOnly: boolean
  createdAt: string
  updatedAt: string
  isOwner: boolean
  ownerName: string
  ownerEmail?: string
  collaborators: ICollaborator[]
}
export interface IAutoSaveRequest {
  script: string | null
  libs: string | null
  versionIndex: number
  projectKey?: string | boolean | number
  isMultiFile?: boolean
  filename: string
  id: number
  lang: string
}
export interface IsaveProjectActualRequest extends Partial<IAutoSaveRequest> {
  filename?: string
  id?: number
  lang?: string
  chatId?: string | null
}
export interface IProject {
  id?: string
  multipleFile?: boolean
  language?: string | null
  readOnly?: boolean
  isOwner?: boolean
}
export interface ILoadProjectsRequest {
  id?: string
  lang?: string
  language?: string
  isMultiFile: boolean | null
  createdAtFrom?: string | null
  updatedAtFrom?: string | null
  createdAtTo?: string | null
  updatedAtTo?: string | null
  name?: string | null
  email?: string | null
  pageIndex?: number
  pageSize?: number
}
export interface IDeleteProjectsRequest {
  id?: string
  lang: string
  isMultiFile: boolean
}

export interface IInviteCollaborationUsers {
  email?: string
  readOnly?: boolean
  projectId: string
  language?: string
  projectName?: string
  id?: string
  forked?: boolean
  invitedUsers?: Array<string>
  studentGroupIds?: Array<string>
}

export enum AccessType {
  RESTRICTED = 'RESTRICTED',
  ACCOUNT = 'ACCOUNT',
  ANYONE = 'ANYONE'
}

export enum AccessPermission {
  READ_ONLY = 'READ_ONLY',
  EDITOR = 'EDITOR'
}

export interface GeneralAccess {
  projectId: number
  type?: AccessType
  permission?: AccessPermission
  link?: string
}

export interface IHttpStatus {
  status: String
  message: String
}

export enum GENERIC_TYPE {
  ADD = 'add',
  FETCH = 'fetch',
  DELETE = 'delete',
  ERROR = 'error',
  SUCCESS = 'success',
  LOADING = 'loading'
}

export enum AUTO_SAVING_ANIMATION_STATUS {
  SAVING = 'saving',
  SAVED = 'saved'
}

export enum EXISTING_PROJECT_SESSION {
  EXISTING = 'ExistingProjects'
}

enum PENDING_STATUS_COLLAB {
  PENDING = 'PENDING'
}

export enum FRAMEWORK_TYPE {
  FRONTEND = 'Front end FrameWorks',
  BACKEND = 'Back end FrameWorks',
  DATABASE = 'Database FrameWorks',
  LANGUAGE = 'Languages'
}

let isProjectAutoSaveing: boolean = false

/**
 * Check for duplicate project name
 * @param projectName - The project name
 * @returns The project response object
 */
const checkForDubplicateProjectName = async (projectName: string) => {
  const requestData: IDubplicateProjectNameRequest = {
    filename: projectName,
    isMultiFile: useIdeStore().isAdvanced,
    lang: useIdeStore().isLanguage
  }
  return await axios
    .post('/api/doodle/checkFileName', requestData)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}
/**
 * Get the sanitized script
 * @param root - the root
 */
const getSanitizedScriptIt = (root: any) => {
  if (root.content) {
    root.content = null
  }

  if (root.codeChanged) {
    root.codeChanged = false
  }

  if (root.children) {
    for (const child of root.children) {
      getSanitizedScriptIt(child)
    }
  }
}
/**
 * @param objData - setting the object
 * @description - setting the existing project object in session storage as an array
 */
const setExistingProjectInSession = (objData: IProject) => {
  const existingProjects = sessionStorage.getItem(EXISTING_PROJECT_SESSION.EXISTING)

  const projectsArray: IProject[] = existingProjects ? JSON.parse(existingProjects) : []

  const projectIndex = projectsArray.findIndex(
    (project) =>
      project.multipleFile === objData.multipleFile && project.language === objData.language
  )

  if (projectIndex === -1) {
    projectsArray.push(objData)
  } else {
    projectsArray[projectIndex] = objData
  }

  sessionStorage.setItem(EXISTING_PROJECT_SESSION.EXISTING, JSON.stringify(projectsArray))
}

/**
 * @param id - ID of the project to delete
 */
const deleteExistingProjectInSession = (id: string) => {
  const existingProjects = sessionStorage.getItem(EXISTING_PROJECT_SESSION.EXISTING)

  if (existingProjects) {
    let projectsArray: IProject[] = JSON.parse(existingProjects)
    projectsArray = projectsArray.filter((project) => project.id !== id)

    sessionStorage.setItem(EXISTING_PROJECT_SESSION.EXISTING, JSON.stringify(projectsArray))
  }
}

/**
 * Save the project to the server
 * @param requestData - The request data
 * @param forkProjectStopLiveCoding - On fork project stop live coding
 * @returns The project response object
 */
const saveLogic = async (
  requestData: IsaveProjectActualRequest,
  forkProjectStopLiveCoding: boolean = false
) => {
  requestData.chatId = useJdroidStore().chatID
  return await axios
    .post('/api/doodle/save', requestData)
    .then(async (response: { data: { project: any }; status: number }) => {
      // logic is for both save and also save as
      let newProject = null
      if (useIdeStore().isAdvanced) {
        newProject = merge(useIdeStore().isProject, response?.data?.project)
        useIdeStore().setProject(null)
        newProject.isOwner = true
        useIdeStore().setProject(newProject)
      } else {
        newProject = response?.data?.project
        newProject.isOwner = true
        useIdeStore().setProject(newProject)
      }
      useProjectManager().setProjectPermissionWithId(newProject)

      useIdeStore().setCodeUpdated(false)

      if (useLiveCodeStore().isLiveCodingActive && !forkProjectStopLiveCoding)
        liveCodingService.closeSharedFile(requestData)
      if (useIdeStore().isShareId) {
        useIdeStore().setSharedId(null)
      }
      const projectObjForSession = {
        id: response.data.project.id,
        language: requestData.lang,
        multipleFile: requestData.isMultiFile
      }
      setExistingProjectInSession(projectObjForSession)
      return response
    })
    .catch((error) => {
      throw error
    })
    .finally(() => {
      isProjectAutoSaveing = false
    })
}
/**
 * Wait for the sync to finish
 * @param requestData - the request data
 * @param isSaveAs - Is save as
 * @param count - the count
 * @param forkProjectStopLiveCoding - On fork project stop live coding
 * @returns The project response object
 */
const multifileWaitForSync = async (
  requestData: IsaveProjectActualRequest,
  isSaveAs: boolean = false,
  count: number = 0,
  forkProjectStopLiveCoding: boolean = false
) => {
  if (projectTreeService.isSyncSuccess() === false) {
    if (count > 1) {
      throw new Error(SYNC_ERROR)
    } else {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      await multifileWaitForSync(requestData, isSaveAs, count + 1, forkProjectStopLiveCoding)
    }
  } else {
    return await saveLogic(requestData, forkProjectStopLiveCoding)
  }
}

/**
 * @description post project load
 * @param count number
 * @returns boolean
 */
const postInitAdvancedIde = async (count: number = 0): Promise<boolean> => {
  if (count > 10) return false
  if (!useIdeStore().project) {
    await new Promise((resolve) => setTimeout(resolve, 1000))
    return postInitAdvancedIde(count + 1)
  } else {
    return true
  }
}

/**
 * Save the project to the server
 * @param isSaveAs - Is save as
 * @param projectName - The project name
 * @param onAutoSave - On auto save
 * @param forkProjectStopLiveCoding - On fork project stop live coding
 * @returns The project response object
 */
const saveProjectActual = async (
  isSaveAs: boolean = false,
  projectName: string = '',
  onAutoSave: boolean = false,
  forkProjectStopLiveCoding: boolean = false
) => {
  let requestData: IsaveProjectActualRequest = {} as IsaveProjectActualRequest
  const libs = useIdeStore().libraries ? useIdeStore().libraries.join(' ') : ''
  if (useIdeStore().isLanguage === 'blockly') {
    requestData = {
      script: JSON.stringify(blocklyService.getBlocklyScript()),
      versionIndex: useIdeStore().versionIndex,
      isMultiFile: false
    }
  } else if (useIdeStore().isAdvanced) {
    await postInitAdvancedIde()
    const root = cloneDeep(useIdeStore().project)

    getSanitizedScriptIt(root.treeData)
    const script = JSON.stringify({ home: root.home, treeData: root.treeData })
    requestData = {
      script: script,
      libs: libs,
      versionIndex: useIdeStore().versionIndex,
      projectKey: useIdeStore().projectKey,
      isMultiFile: true
    }
  } else {
    requestData = {
      script: editorService.getEditorSession(IDECONSTANT.CODE_EDITOR).getValue(),
      libs: libs,
      versionIndex: useIdeStore().versionIndex,
      isMultiFile: false
    }
  }

  if (isSaveAs || !useIdeStore().isProject || !useIdeStore().isProjectId) {
    requestData.filename = projectName
    requestData.id = -1
  } else {
    requestData.filename = useIdeStore().isProject.name
    requestData.id = useIdeStore().isProjectId
  }
  requestData.lang = useIdeStore().isLanguage
  if (useIdeStore().isAdvanced) {
    await projectTreeService.syncBeforeExecute(onAutoSave)
    return multifileWaitForSync(requestData, isSaveAs, 0, forkProjectStopLiveCoding)
  } else {
    return await saveLogic(requestData, forkProjectStopLiveCoding)
  }
}
/**
 * Post auto save
 * @param count - the count
 * @returns The project response object
 */
/**
 * Post auto save
 * @param count - the count
 * @param saveProjectCount - The total number of saveProjectActual called
 * @returns The project response object
 */
const postAutoSave = async (count: number = 0, saveProjectCount: number = 0): Promise<boolean> => {
  if (useIdeStore().isProject && useIdeStore().isProjectId && useIdeStore().isProject.name) {
    useProjectManager().setShowAutoSavingStatus(AUTO_SAVING_ANIMATION_STATUS.SAVING)
    return await saveProjectActual(false, useIdeStore().isProject.name, true)
      .then(() => {
        useProjectManager().setShowAutoSavingStatus(AUTO_SAVING_ANIMATION_STATUS.SAVED)
        return true
      })
      .catch(async () => {
        useProjectManager().setShowAutoSavingStatus(null)
        if (saveProjectCount > 0) return false
        else return await postAutoSave(count, saveProjectCount + 1)
      })
      .finally(() => {
        isProjectAutoSaveing = false
      })
  } else {
    if (count > 10) return false
    await new Promise((resolve) => setTimeout(resolve, 1000))
    return await postAutoSave(count + 1, saveProjectCount)
  }
}

/**
 * Await to auto save
 * @param count - the count
 * @returns The project response object
 */
const awaitToAutoSave = async (count: number = 0): Promise<boolean> => {
  if (isProjectAutoSaveing) {
    if (count > 20) return false
    else {
      await new Promise((resolve) => setTimeout(resolve, 2000))
      return awaitToAutoSave(count + 1)
    }
  } else {
    return await postAutoSave()
  }
}
/**
 * Post init app
 * @param count - the count
 * @returns The project response object
 */
const postInitApp = async (count: number = 0): Promise<boolean> => {
  if (!useAuthStore().isInitiated) {
    if (count < 80) {
      await new Promise((resolve) => setTimeout(resolve, 100))
      return await postInitApp(count + 1)
    } else {
      return false
    }
  } else {
    if (useAuthStore().isUserloggedIn) {
      if (isProjectAutoSaveing) {
        return awaitToAutoSave()
      }
      isProjectAutoSaveing = true
      return await postAutoSave()
    } else {
      return false
    }
  }
}
/**
 * Auto save the code
 * @returns The project response object
 */
const autoSave = async (): Promise<boolean> => {
  if (
    useProjectManager().projectPermissionWithId?.readOnly ||
    useIdeStore().isAdvancedCompilerDatabase
  )
    return false
  return await postInitApp()
}
/**
 * Clear the current project
 */
const clearProject = () => {
  useIdeStore().setProject(null)
  editorService.resetCodeEditor()
  if (useIdeStore().isAdvanced) {
    ideService.initAdvancedIde(ADVANCEDIDETYPE.CLEAR)
  }
}
/**
 * Delete the project from the server
 * @param selectedProject - the selected project
 * @returns The project response object
 */
const deleteProject = async (selectedProject: IProject) => {
  const deleteProjectsRequest: IDeleteProjectsRequest = {
    id: selectedProject.id,
    lang: selectedProject.language as string,
    isMultiFile: selectedProject.multipleFile as boolean
  }

  return await axios
    .post('/api/doodle/deletefile', deleteProjectsRequest)
    .then(() => {
      if (useIdeStore().isProjectId === selectedProject.id) {
        clearProject()
      }
      remove(useIdeStore().isProjects, { id: selectedProject.id })
      useIdeStore().setSelectedProject(null)
    })
    .catch((error) => {
      throw error
    })
}
/**
 * Mark the children of a node as yet to sync
 * So that the children can be synced with the server
 * @param root - The root node
 */
const markChildrenYetToSync = (root: any) => {
  for (const node of root.children) {
    if (!node.children) {
      node.yetToSync = true
    } else {
      markChildrenYetToSync(node)
    }
  }
}

/**
 * Open the project in the IDE
 * @param data - The project data
 * @param awaitsOpenFlag - The flag to wait for the project to open
 * @param count - The count
 * @returns The project response object
 */
const openProject = async (data: any, awaitsOpenFlag: boolean = false, count: number = 0) => {
  if (awaitsOpenFlag && useProjectManager().isAwaitsOpenFlag) {
    if (count > 10) {
      return null
    } else {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      openProject(data, awaitsOpenFlag, count + 1)
    }
  } else {
    useIdeStore().setProject(null)
    if (awaitsOpenFlag) await new Promise((resolve) => setTimeout(resolve, 500))
    editorService.resetCodeEditor()
    if (useIdeStore().isAdvanced) {
      data.treeData = data.script.treeData
      data.home = data.script.home
      data.script = null
    }
    useIdeStore().setProject(cloneDeep(data))
    liveCodingService.initColab()
    if (useIdeStore().isProject.libraries) {
      useIdeStore().libraries = useIdeStore().isProject.libraries
    }
    if (useIdeStore().isAdvanced) {
      await ideService.initAdvancedIde(ADVANCEDIDETYPE.OPEN)
      useIdeStore().setActiveItem()
      for (const node of useIdeStore().isProject.treeData.children) {
        if (!node.children) {
          if ('/' + node.name !== useIdeStore().isProject.home) {
            node.yetToSync = true
          }
        } else {
          markChildrenYetToSync(node)
        }
      }
    } else {
      if (useIdeStore().isBlockly) {
        blocklyService.openBlockly(data.script)
      } else {
        if (useIdeStore().isBlockly) {
          blocklyService.openBlockly(data.script)
        } else {
          if (!useLiveCodeStore().isLiveCodingActive || useIdeStore().isShareId) {
            editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, data?.script)
          }
        }
      }
    }
    useIdeStore().setVersionIndex(data.versionIndex)
    if (useIdeStore().isBlockly) blocklyService.changeBlocklyLanguage()

    useIdeStore().setCodeUpdated(false)
    useProjectManager().setIsAwaitsOpenFlag(false)
    const chatId =
      useProjectManager().isSelectedProject?.chat?.id ||
      useIdeStore().isSelectedProject?.chat?.id ||
      data?.chat?.id ||
      null
    if (!useIdeStore().isHtml && chatId) {
      jdroidService.clearChat()
      await jdroidService.getChatList(chatId)
    }
    useLiveCodeStore().setIsProjectLoaded(true)

    const projectObjForSession = {
      id: data.id,
      language: data.language,
      multipleFile: data.isMultiFile
    }
    setExistingProjectInSession(projectObjForSession)
  }
  useProjectManager().setComingFromProjectManager(false)
}
/**
 * Fetch the project from the server
 * @param project The project to fetch
 * @param lang - selected langugae
 * @returns The project response object
 */
const loadProject = async (project: IProject, lang: string | null = null) => {
  if (useIdeStore().isSelectedProject && project.id === useIdeStore().isSelectedProject.id) {
    return
  }

  useIdeStore().isDefaultIde && useProjectManager().setSelectedProject(null)
  useIdeStore().setSelectedProject(null)
  const isAdvanced = project.multipleFile ? true : false

  const loadProjectRequest: ILoadProjectsRequest = {
    id: project.id,
    lang: lang ? lang : useIdeStore().isHtml ? 'html' : useIdeStore().isLanguage,
    isMultiFile: isAdvanced
  }
  return await axios
    .post('/api/doodle/file', loadProjectRequest)
    .then((response: { data: any }) => {
      const data = response.data
      if (response.data.project.libraries) {
        data.project.libraries = compact(data.project.libraries.split(' '))
      }

      if (isAdvanced) {
        data.project.script = JSON.parse(data.project.script)
      }

      useIdeStore().setSelectedProject(data.project)
      useIdeStore().isDefaultIde && useProjectManager().setSelectedProject(data.project)
      return response.data
    })
    .catch((error) => {
      throw error
    })
}
/**
 * Fetch the projects from the server
 * @param lang - selected langugae
 * @param showAll - show all project
 * @returns The projects response object
 */
const loadProjects = async (lang: string | null = null, showAll: boolean = false) => {
  if (!useAuthStore().isUserloggedIn) return

  const loadProjectsRequest: ILoadProjectsRequest = {
    lang: lang ? lang : useIdeStore().isLanguage,
    isMultiFile: showAll ? null : useIdeStore().isAdvanced
  }
  return await axios
    .post('/api/doodle/myfiles', loadProjectsRequest)
    .then((response: { data: { files: any[] } }) => {
      useIdeStore().setProjects(response.data.files)
      response?.data?.files.forEach((file: IProject) => {
        file.isOwner = true
      })

      return response
    })
    .catch((error) => {
      throw error
    })
}
/**
 * Destroys Output editor if exists
 */
const destroyProjectEditor = () => {
  if (useIdeStore().projectEditor) {
    useIdeStore().projectEditor.destroy()
    useIdeStore().projectEditor = null
  }
}

/**
 * Refresh when model opens
 */
const refresh = () => {
  useIdeStore().setSelectedProject(null)
}
/**
 * open a project when router changes
 * @param count - the count
 * @returns The project response object
 */
const initOnRouterChange = async (count: number = 0) => {
  if (!useIdeStore().openProjectID) return
  if (!useIdeStore().isWindowAce() && useIdeStore().isBlockly && !window['Blockly']) {
    if (count > 30) {
      useIdeStore().setOpenProjectID(null)
      return null
    } else {
      await new Promise((resolve) => setTimeout(resolve, 100))
      initOnRouterChange(count + 1)
    }
  } else {
    const project: IProject = {
      id: useIdeStore().openProjectID as string
    }
    await loadProject(project).then(async () => {
      await openProject(useIdeStore().isSelectedProject)
      useIdeStore().setOpenProjectID(null)
    })
  }
}

let loadProjectController: AbortController | null = null

/**
 * Fetch the projects from the server
 * @param loadProjectsRequest - selected langugae
 * @returns The projects response object
 */
const loadProjectsForProjectManager = async (loadProjectsRequest: Object) => {
  if (loadProjectController) {
    loadProjectController.abort()
  }

  loadProjectController = new AbortController()

  useIdeStore().setSelectedProject(null)
  useProjectManager().setSelectedProject(null)
  if (!useAuthStore().isUserloggedIn) return

  return await axios
    .post('/api/doodle/searchFiles', loadProjectsRequest, { signal: loadProjectController.signal })
    .then((response: { data: any }) => {
      useIdeStore().setProjects(response?.data?.projects)
      useIdeStore().setPageDetail(response?.data?.pageDetail)
      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @description post project load
 * @param count number
 */
const postProjectLoad = async (count: number = 0) => {
  if (count > 10) return
  if (!useIdeStore().isProject && useIdeStore().isAdvanced) {
    await new Promise((resolve) => setTimeout(resolve, 1000))
    postProjectLoad(count + 1)
  } else {
    useProjectManager().setIsAwaitsOpenFlag(false)
  }
}

/**
 *  @description -  Closing the project manager modal and opening the Project modal
 */
const toggledModal = () => {
  const defaultLanguage =
    useIdeStore().isProject?.language &&
    languagesItems.find(
      (language: ILanguage) => language.language === useIdeStore().isProject.language
    )

  if (defaultLanguage) {
    useJdroidStore().setStartCodingLanguage(defaultLanguage)
  } else utilModelsService.openModal(IDEVIEWMODELS.STARTCODING)
  settingService.closePopup(IDEVIEWMODELS.MYPROJECTS)
}

/**
 * @description - getting list of collaborator
 * @param projectValue - gets the selected project data for collab list
 * @returns - returns the list of collaborator added for respective project
 */
const listOfUsers = async (projectValue: IInviteCollaborationUsers) => {
  const paramProjectId = projectValue.id ? projectValue.id : useIdeStore().isProjectId
  const paramProjectLang = projectValue.language ? projectValue.language : useIdeStore().isLanguage
  return await axios
    .get(
      `/api/collaboration/findNotInvitedMembers/project/${paramProjectId}/lang/${paramProjectLang} `
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}
/**
 * @param projectValue - gets the selected project data for collab list
 * @param count - add the count
 * @returns - returns the list of collaborator added for respective project
 * @description - getting the collaborator for respective project
 */
const listOfCollaborator = async (projectValue: IProject, count = 0): Promise<any> => {
  const paramProjectId = projectValue.id ? projectValue.id : useIdeStore().isProjectId
  const paramProjectLang = projectValue.language ? projectValue.language : useIdeStore().isLanguage
  const delay = Math.min(1000 + count * 1000, 5000)

  return await axios
    .get(`/api/collaboration/list/project/${paramProjectId}/lang/${paramProjectLang}`)
    .then(async (response) => {
      if (count < 24) {
        await new Promise((resolve) => setTimeout(resolve, delay))

        if (response.data?.inviteProcessingStatus === PENDING_STATUS_COLLAB.PENDING) {
          return await listOfCollaborator(projectValue as IProject, count + 1)
        } else {
          useProjectManager().setCollaboratorList(response.data?.projects)
          return response
        }
      } else {
        useProjectManager().setCollaboratorList(response.data?.projects)
        return response
      }
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @param inviteCollabReqBody - request body for adding the collaborator
 * @returns - return the added object as response
 * @description - adding the collaborator for respective project
 */
const addCollaboratorInProject = async (inviteCollabReqBody: IInviteCollaborationUsers) => {
  return await axios
    .post('/api/collaboration/invite', inviteCollabReqBody)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @param deleteCollabReqBody - request body for deleting the collaborator
 * @returns - return the deleted object as response
 * @description - deleting the collaborator for respective project
 */
const deleteCollaboratorInProject = async (deleteCollabReqBody: any) => {
  return await axios
    .delete('/api/collaboration/uninvite', { data: deleteCollabReqBody })
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @description getting the collaborator list according to the user
 * @returns returning the collaborator list according to the user
 */
const collaboratorList = async () => {
  return await axios
    .get('/api/collaboration/list')
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * Rename Project Name
 * @param projectName - projectName to update
 * @returns The project response object
 */
const renameProjectName = async (projectName: string) => {
  const requestData: IRenameProjectNameRequest = {
    id: useIdeStore().isProjectId,
    filename: projectName
  }
  return await axios
    .post('/api/doodle/project-name', requestData)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * Create unique has based on string
 * @param str string
 * @returns hash
 */
function simpleHash(str: string) {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash |= 0
  }
  return hash
}

/**
 * This fun generates unique project name using email & current time stamp
 * @returns generated project name
 */
const generateProjectName = () => {
  const email = useAuthStore().userEmail
  const now = new Date().getTime()
  const seed = simpleHash(`${email}${now}`)
  const config: Config = {
    dictionaries: [adjectives, colors, names],
    separator: '-',
    style: 'capital',
    seed
  }

  const projectName: string = uniqueNamesGenerator(config)
  return projectName
}

/**
 * @param inviteCollabReqBody - request body for adding the collaborator
 * @returns - return the added object as response
 * @description - adding the collaborator for respective project
 */
const addCollaboratorAndGroupsInProject = async (
  inviteCollabReqBody: IInviteCollaborationUsers
) => {
  return await axios
    .post('/api/collaboration/saveAndInvite', inviteCollabReqBody)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @param projectId of selected project
 * @returns updated response
 */
const getGeneralAccess = async (projectId: GeneralAccess['projectId']) => {
  return await axios
    .get<GeneralAccess>(`/api/general-access/${projectId}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @param data project id & general permission with type
 * @returns updated response
 */
const updateGeneralAccess = async (data: GeneralAccess) => {
  return await axios
    .post<GeneralAccess>('/api/general-access', data)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * Filter the array according to language type
 * @param languages - taking the array language
 * @param languageType - take language type
 * @returns - the filtered array
 */
const filterAndSortLanguage = (languages: ILanguage[], languageType: string) => {
  return languages
    .filter((lang: ILanguage) => lang.languageType === languageType)
    .sort((a: ILanguage, b: ILanguage) => {
      if (a.isPopularNumber && b.isPopularNumber) {
        return a.isPopularNumber - b.isPopularNumber
      } else {
        if (a.isPopularNumber && !b.isPopularNumber) {
          return -1
        }
        if (b.isPopularNumber && !a.isPopularNumber) {
          return 1
        }
        return 0
      }
    })
}

/**
 * Converting language and divide them into framework
 * @param languages - take the language array
 * @returns - sorted array according to the framework
 */
const groupFrameWorkLanguage = (languages: ILanguage[]) => {
  const frontendLanguages = filterAndSortLanguage(languages, LANGUAGE_TYPE.FRONTEND)
  const backendLanguages = filterAndSortLanguage(languages, LANGUAGE_TYPE.BACKEND)
  const databaseLanguages = filterAndSortLanguage(languages, LANGUAGE_TYPE.DATABASE)
  const programmingLanguages = filterAndSortLanguage(languages, LANGUAGE_TYPE.PROGRAMMING_LANG)

  return [
    { categoryText: FRAMEWORK_TYPE.FRONTEND },
    ...frontendLanguages,
    { categoryText: FRAMEWORK_TYPE.BACKEND },
    ...backendLanguages,
    { categoryText: FRAMEWORK_TYPE.DATABASE },
    ...databaseLanguages,
    { categoryText: FRAMEWORK_TYPE.LANGUAGE },
    ...programmingLanguages
  ]
}

export default {
  checkForDubplicateProjectName,
  saveProjectActual,
  autoSave,
  clearProject,
  refresh,
  deleteProject,
  openProject,
  loadProject,
  loadProjects,
  destroyProjectEditor,
  initOnRouterChange,
  markChildrenYetToSync,
  loadProjectsForProjectManager,
  postProjectLoad,
  toggledModal,
  listOfCollaborator,
  addCollaboratorInProject,
  deleteCollaboratorInProject,
  listOfUsers,
  collaboratorList,
  generateProjectName,
  addCollaboratorAndGroupsInProject,
  getGeneralAccess,
  updateGeneralAccess,
  deleteExistingProjectInSession,
  filterAndSortLanguage,
  groupFrameWorkLanguage,
  renameProjectName
}
