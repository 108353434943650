export enum SUBSCRIPTION_TYPE_ENUM {
  BUNDLE = 'Bundle',
  COMPILER_API = 'Compiler API'
}

export enum SUBSCRIPTION_TYPE {
  BUNDLE = 'bundle',
  COMPILER_API = 'api'
}

export enum SUBSCRIPTION_PLAN_NAME {
  FREE = 'Free',
  STUDENT = 'Student',
  PRO = 'Pro',
  TEACHER = 'Teacher',
  CUSTOM = 'Custom',
  PFREE = 'pFree',
  PCUSTOM = 'pCustom',
  // TEAM = 'Team',
  // CUSTOM_PRO = 'Custom Pro',
  // CUSTOM_TEAM = 'Custom Team',
  CUSTOM_API = 'Custom API'
}

export enum PLATFORM_PLAN_VALUE {} // 🗑️ Removed
// FREE = 'pFree',
// PRO = 'Pro'

export enum ORGANISATION_PLATFORM_PLAN_VALUE {} // 🗑️ Removed
// FREE = 'pFree',
// TEAM = 'Team',
// CUSTOM = 'pCustom'

export enum SUBSCRIPTION_PRODUCT {
  TEACH = 'Teach and Assess',
  COMPILER_API = 'Compiler API',
  CODE = 'Code'
}
